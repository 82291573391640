import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompactDisc } from '@fortawesome/pro-duotone-svg-icons'
import EventItem from './EventItem'

const EventList = ({ data }) => {

  const [songkick, setSongkick] = useState([])
  const [songkickLoading, setSongkickLoading] = useState(false)

  useEffect(() => {
    setSongkickLoading(true)

    const fetchSongkick = () => {
      fetch(
        'https://api.songkick.com/api/3.0/artists/10076965/calendar.json?apikey=uXVXqBVJWP5nwl9d'
      )
        .then((response) => response.json())
        .then((data) => {
          const { resultsPage } = data
          if (
            resultsPage.status === 'ok' &&
            resultsPage.results.event &&
            resultsPage.results.event.length
          ) {
            const songkickEvents = resultsPage.results.event.map((event) => {
              const songkickEvent = {
                id: `sk-${event.id}`,
                url: event.uri,
                venue: event.venue.displayName,
                city: event.venue.metroArea.displayName,
                date: event.start.date,
                time: event.start.time,
              }
              const eventStartDate = moment.utc(event.start.date)
              songkickEvent.eventStartDate = eventStartDate.format('MMMM D')
              if (event.performance.length === 1) {
                songkickEvent.artist = event.performance[0].displayName
              } else {
                songkickEvent.artist = event.performance
                  .reduce((r, c, i) => {
                    if (c.billing === 'headline') r.push(c.displayName)
                    return r
                  }, [])
                  .join(', ')
                songkickEvent.guests = event.performance
                  .reduce((r, c, i) => {
                    if (c.billing === 'support') r.push(c.displayName)
                    return r
                  }, [])
                  .join(', ')
              }
              return songkickEvent
            })
            setSongkick(songkickEvents)
          } else {
            if (resultsPage.error) {
              console.error(resultsPage.error.message)
            } else {
              console.warn('No Songkick events found')
            }
          }
          setSongkickLoading(false)
        })
        .catch((e) => console.error(e))
    }

    fetchSongkick()
  }, [])

  // Combine locally-created events with Songkick events
  const { edges } = data.allMarkdownRemark
  const localPosts = edges.map((edge) => {
    const {
      id,
      frontmatter: {
        artist,
        city,
        date,
        eventStartDate,
        time,
        useTime,
        description,
        guests,
        venue,
      },
    } = edge.node

    return {
      id,
      artist,
      city,
      date,
      eventStartDate,
      time: useTime ? time : null,
      description,
      guests,
      venue,
    }
  })
  const posts = [...localPosts, ...songkick]
  posts.sort((a, b) => (a.date > b.date ? 1 : a.date < b.date ? -1 : 0))

  return (
    <Fragment>
      <h2 id="events">Events</h2>
      { posts.length > 0 &&
        posts.map((post) => <EventItem key={post.id} data={post} />)
      }
      { posts.length === 0 && <p>No events currently scheduled</p> }
      { songkickLoading &&
        <p className="text-muted">
          <FontAwesomeIcon className="mr-1" icon={faCompactDisc} spin />
          Loading from Songkick …
        </p>
      }
    </Fragment>
  )
}

EventList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query eventList {
        allMarkdownRemark(
          filter: {
            frontmatter: { templateKey: { eq: "event" } }
            last30Days: { eq: true }
          }
          sort: { fields: frontmatter___date, order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                artist
                city
                date
                eventStartDate: date(formatString: "MMMM D")
                description
                guests
                venue
                time: date(formatString: "h:mm a")
                useTime
              }
              id
            }
          }
        }
      }
    `}
    render={(data, count) => <EventList data={data} count={count} />}
  />
)
