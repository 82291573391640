import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const defaultArtist = 'Neil Hendry'

const EventItem = ({ data }) => {
  const {
    id,
    artist,
    url,
    city,
    eventStartDate,
    time,
    description,
    guests,
    venue,
  } = data

  return (
    <article className="mb-3" key={id}>
      <strong>
        {url && <a href={url}>{eventStartDate}</a>}
        {!url && eventStartDate}
      </strong>
      {artist !== defaultArtist && (
        <Fragment>
          <br />
          {artist}
        </Fragment>
      )}
      {(venue || city) && (
        <Fragment>
          <br />
          {venue}
          {venue && city && `, `}
          {city}
        </Fragment>
      )}
      {guests && (
        <Fragment>
          <br />
          w/ {guests}
        </Fragment>
      )}
      {description && (
        <Fragment>
          <br />
          {description}
        </Fragment>
      )}
      {time && (
        <Fragment>
          <br />
          {time}
        </Fragment>
      )}
    </article>
  )
}

EventItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    artist: PropTypes.string,
    city: PropTypes.string,
    description: PropTypes.string,
    eventStartDate: PropTypes.string.isRequired,
    time: PropTypes.string,
    guests: PropTypes.string,
    venue: PropTypes.string,
  }),
}

export default EventItem
